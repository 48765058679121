import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function Terms(props) {
  return (
    <>
      <SEO title="awesomeNZ Terms and Conditions - awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../images/slideshow/mtcook_cliff.jpg")}
                alt=""
                class="first last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>Terms and Conditions</h1>
              </div>
              <div class="destination-content">
                {/* ... */}
                <br />
                <br />
                <h2 class="tour">Milford SOUND &amp; Mt Cook Terms</h2>
                <ol>
                  <li>
                    Entrada Travel Group, the operators of awesomeNZ, reserves
                    the right to alter prices and timetables and to arrange
                    alternative transport, routes or sightseeing services
                    without notice.
                  </li>
                  <li>
                    awesomeNZ reserves the right to cancel departures, arrange
                    alternative transport, itineraries and services when
                    necessary. Departures and itineraries are subject to sea and
                    weather conditions. To the extent permitted by law, all our
                    liability for damage to your property, disruption to travel
                    plans, or mental injury is excluded. This exclusion is
                    subject to any rights or remedies you may have under the
                    Consumer Guarantees Act 1993.
                  </li>
                  <li>
                    All prices stated are in New Zealand Dollars and include
                    Goods and Services Tax. Prices are subject to change without
                    notice. A booking applies per transaction.
                  </li>
                  <li>
                    Entrada Travel Group&nbsp;products are sold subject to the
                    conditions of the Contract and Commercial Law Act 2017, Part
                    5, Subpart 1, the terms and conditions published by the
                    carrier and conditions printed on the ticket.
                  </li>
                  <li>
                    Entrada Travel Group&nbsp;and awesomeNZ are not responsible
                    for any loss or damage resulting from the purchase or use of
                    its products or services or the purchase or use of any third
                    party's products or services.
                  </li>
                  <li>
                    No refund will be made against customer failure to report
                    prior to departure. Claims for refunds due to circumstances
                    beyond the control of tour operators, service supplier or
                    customer should be submitted in writing, but no later than
                    30 days after the date of tour departure. It is recommended
                    that customers adequately insure themselves against
                    cancellation charges and the possible loss of money or
                    personal belongings.
                  </li>
                  <li>
                    Cancellations must be received by awesomeNZ no later than
                    2hrs prior to the tour departure. Passengers who have not
                    cancelled will be considered “no shows” and no refunds are
                    available.
                  </li>
                  <li>
                    Fuel: due to the uncertainty of fuel prices, awesomeNZ
                    reserves the rights to add a fuel surcharge to any of their
                    tours.
                  </li>
                  <li>
                    Backpackers receive a discount off standard fares. Correct,
                    valid and current ID cards are required when you purchase
                    your ticket. The driver may ask to see these before
                    accepting backpackers for travel (YHA, VIP, BBH, ISIC,
                    Nomads).
                  </li>
                  <li>
                    Children's fares: 5-14 years inclusive. There is no charge
                    for infants (children 0-4 years) when not occupying a seat,
                    however please advise sales staff when an infant is
                    travelling. Children aged 14 and under cannot travel without
                    an accompanying adult aged 18 or over.
                  </li>
                  <li>
                    While every effort is made to operate the Milford Sound tour
                    in glass roof coaches, due to servicing requirements these
                    vehicles may not always be available.
                  </li>
                </ol>
                <h2 class="tour">Cape Reinga / BAY OF ISLANDS Terms</h2>
                <ol>
                  <li>
                    Please reconfirm with our office the day before departure.
                  </li>
                  <li>
                    All passengers on the Cape Reinga tour must be at least 15
                    years old.
                  </li>
                  <li>
                    awesomeNZ reserves the right to cancel departures, arrange
                    alternative transport, itineraries and services when
                    necessary. Departures and itineraries are subject to sea and
                    weather conditions. To the extent permitted by law, all our
                    liability for damage to your property, disruption to travel
                    plans, or mental injury is excluded. This exclusion is
                    subject to any rights or remedies you may have under the
                    Consumer Guarantees Act 1993.
                  </li>
                  <li>
                    Entry fees and meals are not included except where stated.
                  </li>
                  <li>
                    All prices stated are in New Zealand Dollars and include
                    Goods and Services Tax. Prices are subject to change without
                    notice.
                  </li>
                  <li>
                    Cancellations must be received by awesomeNZ no later than
                    6pm on the day prior to the cruise or tour departure.
                    Passengers who have not cancelled will be considered "no
                    shows" and no refunds are available.
                  </li>
                  <li>
                    Fuel - due to the uncertainty of fuel prices awesomeNZ
                    reserve the right to add&nbsp;a fuel surcharge to any of
                    their tours or cruises.
                  </li>
                </ol>
                <h2 class="tour">Wi-Fi Terms</h2>
                <ol>
                  <li>
                    Wi-Fi is available on selected services and provided free of
                    charge. If the Wi-Fi service is unavailable no refund will
                    be issued.
                  </li>
                </ol>
                <h2 class="tour">Luggage Policy</h2>
                <p>
                  awesomeNZ carries checked luggage subject to the below terms
                  and the Contract and Commercial Law Act 2017, Part 5, Subpart
                  1. All luggage carried must be clearly labelled with the
                  passenger's name, address, contact details and destination.The
                  size, weight and number of luggage items that can be carried
                  is specified below.&nbsp; If a passenger wishes to travel with
                  more luggage than is permitted, or with oversize items, the
                  item(s) may be refused if there is no available space.
                </p>
                <h4>Checked-in luggage</h4>
                <ul>
                  <li>
                    Up to two pieces per person weighing a maximum of 25kg each
                    per person. Each piece of luggage should not exceed total
                    dimensions (length + width + height) of 158cm (62 inches).
                  </li>
                  <li>
                    If you are travelling with a wheelchair, mobility scooter or
                    baby pushchair that can collapse down to fit within the
                    Checked Luggage size and weight dimensions, it can be
                    counted as one of your two Checked Luggage entitlements. If
                    the item cannot be collapsed down to fit our Checked Luggage
                    size and weight dimensions, it will be regarded as Oversize
                    Luggage.
                  </li>
                  <li>
                    Extra luggage may be carried subject to space available at a
                    cost of $10.00 per item per sector, payable to the driver.
                    Please note we cannot guarantee carriage of extra luggage on
                    any specific travel date or service.
                  </li>
                  <li>
                    Chilly bins, boxes and bags carrying fresh or frozen food
                    and/or any frozen goods will not be accepted for
                    transportation.
                  </li>
                  <li>
                    All sports equipment luggage, including golf clubs, must be
                    packaged into a holdall secure bag and must comply with the
                    size and weight dimensions above.&nbsp; The item will be
                    counted as one of your two Checked Luggage entitlements
                  </li>
                  <li>
                    As we are a passenger service, we do not carry freight or
                    unaccompanied luggage.
                  </li>
                  <li>
                    Bicycles must be collapsed down (both wheels removed from
                    the frame) with handlebars turned sideways and the chain
                    covered.&nbsp; Bicycles presented in this fashion will be
                    accepted for travel and counted as one of your two Checked
                    Luggage entitlements.&nbsp; If your bicycle is not collapsed
                    down then it will be regarded as Oversize Luggage.
                  </li>
                  <li>
                    Only awesomeNZ staff may store or remove luggage from the
                    lockers or trailer.
                  </li>
                  <li>
                    awesomeNZ's liability for checked luggage that is under
                    awesomeNZ's care and control is limited to $1,000 per item,
                    subject to the exclusions for unsuitable checked-in luggage
                    and prohibited items below.&nbsp; Passengers are advised to
                    arrange their own insurance cover for amounts over $1,000.
                  </li>
                </ul>
                <br />
                <h4>Unsuitable checked-in luggage</h4>
                <p>
                  The following items are deemed unsuitable for luggage stowed
                  in the luggage lockers and if you do include such items in
                  your stowed luggage, you do this wholly at your own risk and
                  awesomeNZ will not be liable for any costs for inconvenience,
                  replacement, delay, loss or damage you or any other party may
                  suffer
                </p>
                <ul>
                  <li>Passports and travel documents</li>
                  <li>Cameras, video cameras etc</li>
                  <li>Computers or computer equipment</li>
                  <li>Electrical or electronic devices</li>
                  <li>Business documents or important documents</li>
                  <li>Samples</li>
                  <li>Fragile, delicate or perishable goods</li>
                  <li>Valuable items, such as money, jewellery or similar</li>
                  <li>
                    Any item that is not sufficiently packaged to withstand
                    normal effects of carriage by road transport.
                  </li>
                </ul>
                <br />
                <h4>Carry-on luggage</h4>
                <ul>
                  <li>
                    Each customer is limited to one carry-on item with
                    dimensions not exceeding 20cm high x 28cm deep with a
                    maximum length of 400mm. The maximum weight is 5kg.
                  </li>
                  <li>
                    Handbags, umbrellas, walking sticks and other aids are
                    permitted.
                  </li>
                  <li>
                    Chilly bins, boxes and bags carrying food and frozen goods
                    will not be accepted for transportation.
                  </li>
                  <li>
                    You are responsible for your carry-on luggage, and
                    GreatSights accepts no responsibility whatsoever for loss or
                    damage to carry-on luggage.
                  </li>
                </ul>
                <br />
                <h4>Prohibited items</h4>
                <p>
                  The following items are prohibited from being carried on or
                  transported by any awesomeNZ service
                </p>
                <ul>
                  <li>Liquids/creams not carried in watertight containers</li>
                  <li>Flammable liquids</li>
                  <li>Explosives (including fireworks)</li>
                  <li>Poisonous, toxic, or hazardous substances</li>
                  <li>Solvents</li>
                  <li>Fuel (including gas bottles)</li>
                  <li>
                    Batteries (unless used in portable consumer electronic
                    devices)
                  </li>
                  <li>Any form of firearm, weapon, explosives or ammunition</li>
                  <li>Illegal drugs or substances</li>
                  <li>Animals (except for assistance dogs)</li>
                  <li>Frozen food and fresh meat, poultry or fish</li>
                  <li>
                    Any other item deemed unsafe by the driver or awesomeNZ
                    staff
                  </li>
                </ul>
                <br />
                <h4>Right of refusal to carry luggage</h4>
                <p>
                  awesomeNZ has the right to refuse to take luggage if it does
                  not meet the requirements set out in these Terms and
                  Conditions, or if it breaches the Contract and Commercial Law
                  Act 2017, Part 5, Subpart 1.
                </p>
                <ul>
                  <li>
                    If a Passenger travels with more luggage than permitted as
                    above, then the additional item(s) may be refused if there
                    is no available space.
                  </li>
                  <li>
                    Prohibited items are not permitted either on-board or in the
                    luggage compartment.
                  </li>
                  <li>
                    awesomeNZ does not carry unaccompanied parcels under any
                    circumstances. Passengers must never accept luggage or
                    parcels on behalf of someone else for security reasons.
                  </li>
                </ul>
                <p>
                  awesomeNZ will not be responsible or liable for any loss or
                  inconvenience if a Passenger is refused travel under any of
                  these circumstances.
                </p>
                <h4>Queries and complaints</h4>
                <p>
                  If you have any queries, claims, complaints or Lost Property
                  related to awesomeNZ, please contact us by:
                  <br />
                  <br />
                  1. E-mail:{" "}
                  <a href="mailto:info@awesomenz.com">info@awesomenz.com</a> or
                  <br />
                  2. Post: Entrada Travel Group, PO Box 26-601, Epsom, Auckland
                  or
                  <br />
                  3. Phone: <a href="tel:0800264536">0800 264 536</a>
                  <br />
                  <br />
                  Claims for compensation must be received by awesomeNZ within 7
                  days of the date of travel.&nbsp; To make a claim please
                  provide full contact details and time and date of
                  journey.&nbsp;
                </p>
                <h2 class="tour">Image Use/Privacy</h2>
                <p>
                  1. The passenger acknowledges and gives consent for
                  awesomeNZ/Entrada Travel Group by itself or through an agent
                  to take photographs, audio or video recordings, or other forms
                  of recordings that capture the passenger's image, likeness,
                  voice, performance or all of these ("Images") during the
                  course of this activity / tour. The passenger acknowledges and
                  gives consent for&nbsp;Entrada Travel Group to receive and use
                  Images of the passenger from other.
                </p>
                <p>
                  2.&nbsp;Entrada Travel Group may use Images for marketing and
                  promoting its services. The ways in which the Images will be
                  used for marketing purposes include, but are not limited to,
                  incorporating the Images in print, broadcast, internet media,
                  direct marketing channels, downloadable content and
                  applications, and all other media, platform or technology now
                  known or to be developed for distribution around the world.
                  The Images may be altered, modified, changed, combined or
                  incorporated into other works.
                </p>
                <p>
                  3. The passenger has a right to request access to the
                  passenger's Images and to correction of those Images to ensure
                  they are complete and not misleading. However, the passenger
                  acknowledges that if the Images have been incorporated into
                  marketing materials, it may not be reasonably practicable
                  for&nbsp;Entrada Travel Group to make any changes to the
                  Images. The passenger authorises&nbsp;Entrada Travel Group to
                  refuse the request to correction in such circumstances.
                </p>
                <p>
                  <a
                    title="Entrada Travel Group Privacy Policy"
                    href="https://entradatravelgroup.com/wp-content/uploads/2019/06/Entrada-Travel-Group-Privacy-Policy.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    See&nbsp;Entrada Travel Group Privacy Policy
                  </a>
                </p>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default Terms
